/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { debounce } from "debounce";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import SearchIcon from "../Assets/Icons/SearchIcon";
import DropDown from "../Components/DropDown";
import PaginationComponent from "../Components/PaginationComponent/PaginationComponent";
import {
  getCoursesUapply,
  searchSubjects,
  searchUniversities,
} from "../Services/Service";

function Uapply() {
  const [courselevel, setcourselevel] = useState("");
  const [searchText, setsearchText] = useState("");
  const [totalPage, settotalPage] = useState(0);
  const [courses, setcourses] = useState([]);
  const [page, setpage] = useState(1);
  const [subjects, setsubjects] = useState([]);
  const [selectedSubject, setselectedSubject] = useState("");
  const [city, setcity] = useState("");
  const [totalCourses, settotalCourses] = useState(0);
  const [attendanceTypes, setattendanceTypes] = useState("");
  const [searchInstituteText, setsearchInstituteText] = useState("");
  const [universities, setuniversities] = useState([]);
  const [selectedUni, setselectedUni] = useState("");

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const courses = await getCoursesUapply({
        subject: selectedSubject,
        attendanceTypes: attendanceTypes.replaceAll(" ", ""),
        courseLevel: courselevel,
        institution: selectedUni,
        city: city,
        placementAvailable: "",
        courseDurationValues: "",
        intakeMonths: "",
      });
      setcourses(courses.results);
      settotalCourses(courses.totalResults);
      settotalPage(courses.totalPages);
    }

    fetchData();
  }, []);

  return (
    <div>
      <Row>
        <Col md={3}>
          <div className="d-flex flex-row " style={{ position: "relative" }}>
            <div className="search-icon">
              <SearchIcon />
            </div>
            <input
              placeholder="Input Text"
              name="name"
              onChange={debounce(async (e) => {
                setsearchText(e.target.value);
                if (!e.target.value) {
                  setsubjects([]);
                }
                const res = await searchSubjects({ text: e.target.value });
                setpage(1);
                setsubjects(res.results);
              }, 500)}
              type="text"
              className="search-input"
              //   value={searchText}
            />
          </div>
          {selectedSubject ? (
            <div
              style={{
                background: "rgb(0 0 0 / 18%)",
                color: "#000",
                borderRadius: 40,
                paddingLeft: 20,
                marginTop: 10,
                width: "max-content",
                textTransform: "capitalize",
              }}
              className="d-flex flex-row justify-content-between"
            >
              {selectedSubject.length > 20
                ? `${selectedSubject.replace(/-/g, " ").substring(0, 20)}...`
                : selectedSubject}{" "}
              <div
                style={{ paddingRight: 20, cursor: "pointer", marginLeft: 10 }}
                onClick={async () => {
                  setselectedSubject("");
                  setsearchText("");
                  const courses = await getCoursesUapply({
                    subject: "",
                    attendanceTypes: attendanceTypes.replaceAll(" ", ""),
                    courseLevel: courselevel,
                    institution: selectedUni,
                    city: city,
                    placementAvailable: "",
                    courseDurationValues: "",
                    intakeMonths: "",
                  });
                  setcourses(courses.results);
                  settotalCourses(courses.totalResults);
                  settotalPage(courses.totalPages);
                }}
              >
                X
              </div>
            </div>
          ) : null}

          {subjects.length ? (
            <div
              className="d-flex flex-column"
              style={{
                background: "#fff",
                padding: 10,
                position: "absolute",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                borderRadius: 8,
              }}
            >
              {" "}
              {subjects.map((a) => (
                <span
                  onClick={async () => {
                    setselectedSubject(a.slug);
                    setsubjects([]);
                    const courses = await getCoursesUapply({
                      subject: a.slug,
                      attendanceTypes: attendanceTypes.replaceAll(" ", ""),
                      courseLevel: courselevel,
                      institution: selectedUni,
                      city: city,
                      placementAvailable: "",
                      courseDurationValues: "",
                      intakeMonths: "",
                    });
                    setcourses(courses.results);
                    settotalCourses(courses.totalResults);
                    settotalPage(courses.totalPages);
                  }}
                  style={{ paddingBottom: 5, cursor: "pointer" }}
                >
                  {a.name}
                </span>
              ))}{" "}
            </div>
          ) : null}
        </Col>
        <Col md={3}>
          <DropDown
            data={[
              { name: "Undergraduate" },
              { name: "Postgraduate" },
              { name: "Foundation" },
              { name: "Doctorate" },
            ]}
            onClick={async (x) => {
              setcourselevel(x.name);
              const courses = await getCoursesUapply({
                subject: selectedSubject,
                attendanceTypes: attendanceTypes.replaceAll(" ", ""),
                courseLevel: x.name,
                institution: selectedUni,
                city: city,
                placementAvailable: "",
                courseDurationValues: "",
                intakeMonths: "",
              });
              setcourses(courses.results);
              settotalCourses(courses.totalResults);
              settotalPage(courses.totalPages);
            }}
            name={!courselevel ? "Course Level" : courselevel}
          />
        </Col>
        <Col md={3}>
          <DropDown
            data={[{ name: "Full Time" }, { name: "Distance Learning" }]}
            onClick={async (x) => {
              setattendanceTypes(x.name);
              const courses = await getCoursesUapply({
                subject: selectedSubject,
                attendanceTypes: x.name.replaceAll(" ", ""),
                courseLevel: courselevel,
                institution: selectedUni,
                city: city,
                placementAvailable: "",
                courseDurationValues: "",
                intakeMonths: "",
              });
              setcourses(courses.results);
              settotalCourses(courses.totalResults);
              settotalPage(courses.totalPages);
            }}
            name={!attendanceTypes ? "Mode of Study" : attendanceTypes}
          />
        </Col>
        <Col md={3}>
          <div className="d-flex flex-row " style={{ position: "relative" }}>
            <div className="search-icon">
              <SearchIcon />
            </div>
            <input
              placeholder="Institutions"
              name="name"
              onChange={debounce(async (e) => {
                setsearchInstituteText(e.target.value);
                if (!e.target.value) {
                  setuniversities([]);
                }
                const res = await searchUniversities({ text: e.target.value });
                setpage(1);
                setuniversities(res);
              }, 500)}
              type="text"
              className="search-input"
              //   value={searchText}
            />
          </div>
          {selectedUni ? (
            <div
              style={{
                background: "rgb(0 0 0 / 18%)",
                color: "#000",
                borderRadius: 40,
                paddingLeft: 20,
                marginTop: 10,
                width: "max-content",
                textTransform: "capitalize",
              }}
              className="d-flex flex-row justify-content-between"
            >
              {selectedUni.length > 20
                ? `${selectedUni.replace(/-/g, " ").substring(0, 20)}...`
                : selectedUni}
              <div
                style={{ paddingRight: 20, cursor: "pointer", marginLeft: 10 }}
                onClick={async () => {
                  setselectedUni("");
                  setsearchInstituteText("");
                  const courses = await getCoursesUapply({
                    subject: "",
                    attendanceTypes: attendanceTypes.replaceAll(" ", ""),
                    courseLevel: courselevel,
                    institution: "",
                    city: city,
                    placementAvailable: "",
                    courseDurationValues: "",
                    intakeMonths: "",
                  });
                  setcourses(courses.results);
                  settotalCourses(courses.totalResults);
                  settotalPage(courses.totalPages);
                }}
              >
                X
              </div>
            </div>
          ) : null}

          {universities.length ? (
            <div
              className="d-flex flex-column"
              style={{
                background: "#fff",
                padding: 10,
                position: "absolute",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                borderRadius: 8,
              }}
            >
              {" "}
              {universities.map((a) => (
                <span
                  onClick={async () => {
                    setselectedUni(a.name);
                    setuniversities([]);
                    const courses = await getCoursesUapply({
                      subject: selectedSubject,
                      attendanceTypes: attendanceTypes.replaceAll(" ", ""),
                      courseLevel: courselevel,
                      institution: a.name,
                      city: city,
                      placementAvailable: "",
                      courseDurationValues: "",
                      intakeMonths: "",
                    });
                    setcourses(courses.results);
                    settotalCourses(courses.totalResults);
                    settotalPage(courses.totalPages);
                  }}
                  style={{ paddingBottom: 5, cursor: "pointer" }}
                >
                  {a.name}
                </span>
              ))}{" "}
            </div>
          ) : null}
        </Col>
      </Row>
      <Row style={{ paddingTop: 40 }}>
        <Col md={12}>
          {courses.length ? <p>{totalCourses} courses found</p> : null}
          {courses.map((a) => (
            <div
              style={{
                padding: 10,
                background: "#fff",
                marginBottom: 10,
                borderRadius: 8,
              }}
              className="d-flex flex-column"
            >
              <h3 style={{ fontSize: 16 }}>{a.name}</h3>
              <span style={{ paddingBottom: 10, fontSize: ".75rem" }}>
                {a.courseLevel} • {a.attendanceTypes}
              </span>
              {a.approxAnnualFee === 0 || !a.approxAnnualFee ? null : (
                <h3 style={{ fontSize: 16 }}>
                  {a.approxAnnualFee
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  {a.currency}{" "}
                  <span style={{ fontSize: ".75rem" }}>first year</span>
                </h3>
              )}{" "}
              <span style={{ fontSize: ".75rem" }}>{a.institution}</span>
            </div>
          ))}
        </Col>
      </Row>

      {courses?.length ? (
        <PaginationComponent
          page={parseInt(page)}
          totalPage={totalPage}
          onPageClick={async (x) => {
            setpage(parseInt(x));

            setsearchText("");
            const courses = await getCoursesUapply({
              subject: selectedSubject,
              attendanceTypes: attendanceTypes.replaceAll(" ", ""),
              courseLevel: courselevel,
              institution: selectedUni,
              city: city,
              placementAvailable: "",
              courseDurationValues: "",
              intakeMonths: "",
              limit: 10,
              page: x,
            });
            setcourses(courses.results);
            settotalCourses(courses.totalResults);
            settotalPage(courses.totalPages);
          }}
          onChange={async () => {
            setpage(parseInt(page) + 1);
            if (courses?.length === 10) {
              const data = await getCoursesUapply({
                subject: selectedSubject,
                attendanceTypes: attendanceTypes.replaceAll(" ", ""),
                courseLevel: courselevel,
                institution: selectedUni,
                city: city,
                placementAvailable: "",
                courseDurationValues: "",
                intakeMonths: "",
                limit: 10,
                page: page + 1,
              });
              setcourses(data.results);
              settotalCourses(data.totalResults);
              settotalPage(data.totalPages);
            }
          }}
          onChangePrev={async () => {
            setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);

            const data = await getCoursesUapply({
              subject: selectedSubject,
              attendanceTypes: attendanceTypes.replaceAll(" ", ""),
              courseLevel: courselevel,
              institution: selectedUni,
              city: city,
              placementAvailable: "",
              courseDurationValues: "",
              intakeMonths: "",
              limit: 10,
              page: page - 1,
            });
            setcourses(data.results);
            settotalPage(data.totalPages);
            settotalPage(data.totalPages);
          }}
        />
      ) : null}
    </div>
  );
}

export default Uapply;
