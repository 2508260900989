import React, { Component } from "react";
import ReactDOM from "react-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isFocused ? "#999999" : null,
      // backgroundColor: isSelected ? "#fff" : null,
      cursor: "pointer",
      color: "#333333",
      "&:hover": {},
      "&:active": { backgroundColor: "transparent" },
    };
  },
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="d-flex flex-row align-items-center">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />
            <span className="checkmark"></span>
          </label>
          <label>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};
export default class DropDownComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
    };
  }

  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  render() {
    return (
      <div style={this.props.style}>
        <ReactSelect
          styles={colourStyles}
          options={
            this.props.courses
              ? this.props.courses?.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))
              : this.props.levels?.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))
          }
          isMulti
          isSearchable={false}
          menuColor="red"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
          }}
          className="input"
          onChange={(selected) => this.props.handleChange(selected)}
          allowSelectAll={true}
          value={this.props.optionSelected}
        />
        {this.props.error ? (
          <div>
            <span
              style={style.error}
              className="error"
            >{`Please select the ${this.props.errorMessage}  `}</span>
          </div>
        ) : null}
      </div>
    );
  }
}

const style = {
  error: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#FB000A",
    marginTop: -8,
  },
};
const rootElement = document.getElementById("root");
ReactDOM.render(<DropDownComponent />, rootElement);
