import axios from "axios";
import {
  ApiV1,
  CLASSES,
  GET_COURSES,
  LEVEL,
  PARTNER,
  DAYSPERWEEK,
  AGEGROUP,
  GET_DURATION,
  COUNTRY,
  CURRENCY,
  TYPES,
  OPTIONS,
  DISCOUNT,
  COUPON,
  GET_BOOKINGS,
  BOOKINGS,
  ReviewEmail,
  PRIVATE_CLASSES,
  PLACEMENT,
  MAJORS,
} from "../API";

export const getClasses = async (data) => {
  try {
    const res = await ApiV1.get(`${CLASSES}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        status: data.status,
        populate: data.search,
        paginated: true,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getClassesWithoutPagination = async () => {
  try {
    const res = await ApiV1.get(`${CLASSES}?paginated=${false}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getBookings = async (data) => {
  try {
    const res = await ApiV1.get(`${GET_BOOKINGS}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        status: data.status,
        level: data.level,
        duration: data.duration,
        course: data.course,
        ageGroup: data.ageGroup,
        populate: data.search,
        stage: data.stage,
        optionType: data.optionType,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getBookingsByMonth = async (data) => {
  try {
    const res = await ApiV1.get(`${GET_BOOKINGS}months`, {
      params: {
        limit: data.limit,
        page: data.page,
        sortBy: data.sortBy,
        status: data.status,
        month: data.month,
        year: data.year,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPlacements = async (data) => {
  try {
    const res = await ApiV1.get(`${PLACEMENT}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const searchPlacements = async (text, data) => {
  try {
    const res = await ApiV1.get(`${PLACEMENT}/search/${text.text}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        status: data.status,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const searchClass = async (data) => {
  try {
    const res = await ApiV1.get(`${CLASSES}/search/${data}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchBooking = async (text, data) => {
  try {
    const res = await ApiV1.get(`${BOOKINGS}/search/${text.text}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        status: data.status,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updateBooking = async (id, data) => {
  try {
    const res = await ApiV1.patch(`${BOOKINGS}/${id}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const sortClass = async (data) => {
  try {
    const res = await ApiV1.get(`${CLASSES}/sort/${data}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getCoupons = async () => {
  try {
    const res = await ApiV1.get(`${COUPON}`);
    return res.data.results;
  } catch (error) {
    throw error;
  }
};
export const getReviewEmails = async (data) => {
  try {
    const res = await ApiV1.get(`${ReviewEmail}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updateReviewEmail = async (id, data) => {
  try {
    const res = await ApiV1.patch(`${ReviewEmail}/${id}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getReviewEmailById = async (id) => {
  try {
    const res = await ApiV1.get(`${ReviewEmail}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const createReviewEmail = async (data) => {
  try {
    const res = await ApiV1.post(`${ReviewEmail}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const createDiscount = async (data) => {
  try {
    const res = await ApiV1.post(`${DISCOUNT}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createCoupon = async (data) => {
  try {
    const res = await ApiV1.post(`${COUPON}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getCourses = async (country) => {
  try {
    const res = await ApiV1.get(`${GET_COURSES}`, {
      params: { country },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const sendWelcomeEmail = async (data) => {
  console.log(data);
  try {
    const res = await ApiV1.post(`${BOOKINGS}/sendWelcomeEmail`, {
      ...data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCourseById = async (id) => {
  try {
    const res = await ApiV1.get(`${GET_COURSES}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getLevels = async () => {
  try {
    const res = await ApiV1.get(`${LEVEL}`);
    return res.data.results;
  } catch (error) {
    throw error;
  }
};
export const createOptions = async (data) => {
  try {
    const res = await ApiV1.post(`${OPTIONS}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updateOption = async (id, data) => {
  try {
    const res = await ApiV1.patch(`${OPTIONS}/${id}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getOptions = async () => {
  try {
    const res = await ApiV1.get(`${OPTIONS}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getDiscountTypes = async () => {
  try {
    const res = await ApiV1.get(`${DISCOUNT}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getOptionsByCourse = async (id) => {
  try {
    const res = await ApiV1.get(`${OPTIONS}/course/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getLevelById = async (id) => {
  try {
    const res = await ApiV1.get(`${LEVEL}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getOptionsByCountry = async (id) => {
  try {
    const res = await ApiV1.get(`${OPTIONS}/country/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getTypes = async () => {
  try {
    const res = await ApiV1.get(`${TYPES}`);
    return res.data.results;
  } catch (error) {
    throw error;
  }
};

export const getPartners = async () => {
  try {
    const res = await ApiV1.get(`${PARTNER}`);
    return res.data.results;
  } catch (error) {
    throw error;
  }
};

export const getPartnerById = async (id) => {
  try {
    const res = await ApiV1.get(`${PARTNER}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getDurations = async () => {
  try {
    const res = await ApiV1.get(`${GET_DURATION}`);
    return res.data.results;
  } catch (error) {
    throw error;
  }
};
export const getCountries = async () => {
  try {
    const res = await ApiV1.get(`${COUNTRY}`);
    return res.data.results;
  } catch (error) {
    throw error;
  }
};

export const getCurrencies = async () => {
  try {
    const res = await ApiV1.get(`${CURRENCY}`);
    return res.data.results;
  } catch (error) {
    throw error;
  }
};

export const getDaysPerWeek = async () => {
  try {
    const res = await ApiV1.get(`${DAYSPERWEEK}`);
    return res.data.results;
  } catch (error) {
    throw error;
  }
};
export const getDaysPerWeekbyId = async (id) => {
  try {
    const res = await ApiV1.get(`${DAYSPERWEEK}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getAgeGroup = async () => {
  try {
    const res = await ApiV1.get(`${AGEGROUP}`);
    return res.data.results;
  } catch (error) {
    throw error;
  }
};

export const createprivateClass = async (data) => {
  try {
    const res = await ApiV1.post(`${PRIVATE_CLASSES}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createClass = async (data) => {
  try {
    const res = await ApiV1.post(`${CLASSES}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateClass = async (id, data) => {
  try {
    const res = await ApiV1.patch(`${CLASSES}/${id}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteClass = async (id) => {
  try {
    const res = await ApiV1.delete(`${CLASSES}/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteOptions = async (id) => {
  try {
    const res = await ApiV1.delete(`${OPTIONS}/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const deleteCoupon = async (id) => {
  try {
    const res = await ApiV1.delete(`${COUPON}/${id}`);
    console.log(res);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateDiscount = async (id, discountAmount) => {
  try {
    const res = await ApiV1.patch(`${OPTIONS}/${id}/discount`, {
      ...discountAmount,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateCoupon = async (id, body) => {
  try {
    const res = await ApiV1.patch(`${COUPON}/${id}`, {
      ...body,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const searchMajors = async (data) => {
  try {
    const res = await ApiV1.get(`${MAJORS}/search/${data.text}`, {
      params: {
        limit: data.limit,
        page: data.page,
        // sortBy: data.sortBy,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getMajors = async (data) => {
  try {
    const res = await ApiV1.get(`${MAJORS}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        // sortBy: data.sortBy,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};
export const updateMajorUser = async (id, body) => {
  try {
    const res = await ApiV1.patch(`${MAJORS}/${id}`, {
      ...body,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteMajorUser = async (id) => {
  try {
    const res = await ApiV1.delete(`${MAJORS}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const searchSubjects = async (data) => {
  try {
    const res = await axios.get(
      `https://uapply-staging-api-dot-digital-vim-270607.appspot.com/v1/subjects/search/${data.text}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchUniversities = async (data) => {
  try {
    const res = await axios.get(
      `https://uapply-staging-api-dot-digital-vim-270607.appspot.com/v1/universities/search/${data.text}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCoursesUapply = async (data) => {
  try {
    const res = await axios.get(
      `https://uapply-staging-api-dot-digital-vim-270607.appspot.com/v1/courses/?subjects=${data.subject}&country=United%20Kingdom&attendanceTypes=${data.attendanceTypes}&courseLevel=${data.courseLevel}&institution=${data.institution}&intakeMonths=${data.intakeMonths}&courseDurationValues=${data.courseDurationValues}&placementAvailable=${data.placementAvailable}&city=${data.city}`,
      {
        params: {
          limit: data.limit,
          page: data.page,
        },
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};
