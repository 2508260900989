/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from "debounce";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import SearchIcon from "../Assets/Icons/SearchIcon";
import ButtonComponent from "../Components/ButtonComponent";
import Loader from "../Components/Loader/Loader";
import MajorTable from "../Components/MajorTable/MajorTable";
import PaginationComponent from "../Components/PaginationComponent/PaginationComponent";
import { getMajors, searchMajors } from "../Services/Service";

export default function Major() {
  const [totalPage, settotalPage] = useState("");
  const [searchText, setsearchText] = useState("");
  const [majors, setmajors] = useState("");
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [loading, setloading] = useState(false);
  const [studentsArr, setstudentsArr] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setloading(true);
      const res = await getMajors({
        limit: limit,
        page: 1,
      });
      settotalPage(res.totalPages || null);
      setmajors(res.results);
      setloading(false);
    }

    fetchData();
  }, []);

  const getMajorsData = async (data) => {
    setloading(true);
    const res = await getMajors({
      limit: data,
      page: 1,
    });
    settotalPage(res.totalPages || null);
    setmajors(res.results);
    setloading(false);
  };
  const reload = async () => {
    setloading(true);
    const res = await getMajors({
      limit: limit,
      page: page,
    });
    settotalPage(res.totalPages || null);
    setmajors(res.results);
    setloading(false);
  };

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-row " style={{ position: "relative" }}>
          <div className="search-icon">
            <SearchIcon />
          </div>
          <input
            placeholder="Input Text"
            name="name"
            onChange={debounce(async (e) => {
              setsearchText(e.target.value);
              if (e.target.value === "") {
                const res = await getMajors({
                  limit: limit,
                  page: page,
                });
                settotalPage(res.totalPages || null);
                setmajors(res.results);
              } else {
                const res = await searchMajors(
                  { text: e.target.value },
                  {
                    status: "Paid",

                    limit: limit,
                    page: 1,
                  }
                );
                setpage(1);
                settotalPage(res.totalPages || null);
                setmajors(res.results);
              }
            }, 500)}
            type="text"
            className="search-input"
          />
        </div>
        <ButtonComponent
          onClick={() => {
            let phoneNos;
            let phone;
            if (studentsArr.length) {
              phoneNos = studentsArr.map((x) =>
                `${x.countryCode || "+965"}${x.phoneNo}`.replace(
                  /[^A-Z0-9]/gi,
                  ""
                )
              );
              phone = [...new Set(phoneNos)];
            } else {
              phoneNos = majors.map((x) =>
                `${x.countryCode || "+965"}${x.phoneNo}`.replace(
                  /[^A-Z0-9]/gi,
                  ""
                )
              );
              phone = [...new Set(phoneNos)];
            }

            navigator.clipboard.writeText(phone).then(
              function () {
                alert(" Copying to clipboard was successful!");
              },
              function (err) {
                console.error("Async: Could not copy text: ", err);
              }
            );
          }}
          text="Export Numbers"
        />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div style={{ marginTop: 20 }}>
          <MajorTable
            setstudentsArr={setstudentsArr}
            studentsArr={studentsArr}
            header={[
              "Select",
              "Name / Date of test",
              "Email / Dob",
              "Phone Number",
              "Nationality",
              "Details",
              "Actions",
            ]}
            tableData={!majors ? [] : majors}
            reload={() => reload()}
          />
        </div>
      )}

      {majors?.length ? (
        <div className="d-flex justify-content-between align-items-center">
          <Dropdown>
            <Dropdown.Toggle
              className="dd-custom-toggle"
              id="dropdown-custom-components"
              style={{ color: "#000" }}
            >
              {limit}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ minWidth: "max-content" }}
              className="dd-custom-menu"
            >
              <Dropdown.Item
                target="_blank"
                className="dd-custom-items"
                onClick={() => {
                  getMajorsData(50);
                  setlimit(50);
                }}
              >
                50
              </Dropdown.Item>

              <Dropdown.Item
                target="_blank"
                className="dd-custom-items"
                onClick={() => {
                  getMajorsData(100);
                  setlimit(100);
                }}
              >
                100
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <PaginationComponent
            page={parseInt(page)}
            totalPage={totalPage}
            onPageClick={async (x) => {
              setloading(true);
              if (searchText) {
                const res = await searchMajors(
                  { text: searchText },
                  { limit: limit, page: x }
                );
                settotalPage(res.totalPages || null);
                setmajors(res.results);
              } else {
                const data = await getMajors({
                  limit: limit,
                  page: x,
                });
                setmajors(data.results);
                settotalPage(data.totalPages);
                setpage(parseInt(x));
              }
              setloading(false);
            }}
            onChange={async () => {
              setloading(true);
              if (searchText) {
                const res = await searchMajors(
                  { text: searchText },
                  {
                    status: "Paid",
                    limit: limit,
                    page: page + 1,
                  }
                );
                settotalPage(res.totalPages || null);
                searchMajors(res.results);
              } else {
                if (majors?.length === limit) {
                  const data = await getMajors({
                    limit: limit,
                    page: page + 1,
                  });
                  setmajors(data.results);
                  settotalPage(data.totalPages);
                }
              }
              setloading(false);
              setpage(parseInt(page) + 1);
            }}
            onChangePrev={async () => {
              setloading(true);
              if (searchText) {
                const res = await searchMajors(
                  { text: searchText },
                  {
                    status: "Paid",
                    limit: limit,
                    page: page - 1,
                  }
                );
                settotalPage(res.totalPages || null);
                setmajors(res.results);
              } else {
                const data = await getMajors({
                  limit: limit,
                  page: page - 1,
                });
                setmajors(data.results);
                settotalPage(data.totalPages);
              }
              setloading(false);
              setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
